import { packageTrip } from '@/core/models/packageTrip';
import { honeymoon } from '@/core/models/honeymoon';
import { configuration } from '@/core/models/configuration';
import { FindAll } from '@/core/services/configuration';
import * as storage from "./storage"
import { ref, computed } from "vue";

const state = ref<configuration>({});
const packInfo = ref<any>({});

const pack = ref<packageTrip>({
    dates: [], accommodation: [], airfare: []
});


const escolhaListaPresente = ref<string>("");
const flight = ref<any>({});
const store = ref<any>({});
const hotel = ref<any>({});
const pacote = ref<any>({});
const pesquisaHotel = ref<any>({});
const comparador = ref<any>({});
const selecionadosSeguroComparador = ref<any>({});
const getters = {
    getSite: computed(() => {
        return state.value;
    }),
};
const openModalLogin = ref<boolean>(false);
const actions = {
    GetConfig: async () => {
        try {
            const Confg = await FindAll();
            ActionSetConfig(Confg.items[0])
        } catch (error: any) {
            error
        }
    },
    SetConfig: async (payload: any) => {
        ActionSetConfig(payload)
    },

    ActionCheckConfig() {
        try {
            const configuration = storage.getGlobalConfig();
            if (state.value) {
                return Promise.resolve(state.value);
            } else {
                ActionSetConfig(storage.getGlobalConfig()); ''
                return Promise.resolve(configuration);
            }
        } catch (error) {
            error
        }
    },
    ActionSetPackege(item: packageTrip) {
        pack.value = item;
    }
}
function ActionSetConfig(payload: configuration) {
    state.value = payload;
    store.value = state.value;
    storage.setGlobalConfig(payload)
}

export { getters, state, actions, pack, pacote, hotel, pesquisaHotel, openModalLogin, flight, packInfo, escolhaListaPresente, comparador, selecionadosSeguroComparador };







